import { selectFeatureFlags } from '@noths/polaris-client-user-configuration';
import { createAsyncThunk, isRejected } from '@reduxjs/toolkit';
import type { ParsedUrlQueryInput } from 'querystring';

import { BrowseProvider, SearchPersonalisation } from 'src/constants/featureFlags';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPageType } from 'src/redux/navigation/selectors';
import { selectPartnerShortcode } from 'src/redux/products/selectors';
import {
  CATEGORY_NOT_FOUND_ERROR,
  PARTNER_NOT_FOUND_ERROR,
} from 'src/redux/products/thunks/thunkErrors';
import type { RequestProductsActionPayload, TriggerName } from 'src/redux/products/types';
import {
  getAllProductsListingsData,
  getCategoryListingsData,
  getPartnerListingsData,
  getSearchListingsData,
} from 'src/services/browse-data-api/getListingsData';
import { PageType } from 'src/types/navigation';
import { adaptBrowseDataAPIBodyToReduxActionPayload } from '../adapters/adaptBrowseDataAPIBodyToReduxActionPayload';

export interface RequestProductsOptions {
  cookie?: string;
  isHealthcheckRequest?: boolean;
  query: ParsedUrlQueryInput;
  replaceProducts?: boolean;
  requestIsLoadTest?: boolean;
  triggerName?: TriggerName;
}

export const requestProducts = createAsyncThunk<
  RequestProductsActionPayload,
  RequestProductsOptions
>(
  'products/requestProducts',
  async ({ cookie, isHealthcheckRequest, query, requestIsLoadTest, triggerName }, { getState }) => {
    if (isHealthcheckRequest) {
      // Dynamically import json file to prevent it being included on the client. Healthcheck request only happens server side.
      const { healthCheckCategoryResponseBody } = await import(
        'src/services/browse-data-api/fixtures/healthCheckCategoryResponseBody'
      );

      return {
        data: adaptBrowseDataAPIBodyToReduxActionPayload(healthCheckCategoryResponseBody),
      };
    }

    const state = getState() as ReduxApplicationState;
    const { deliveryZone, featureFlags, loop54UserId } = state.userConfiguration;
    const currencyCode = state.userConfiguration.currencyCode!;

    const commonGetDataArgs = {
      cookie,
      ...(currencyCode && { currencyCode }),
      ...(deliveryZone && { deliveryZone }),
      featureFlags,
    };
    let response;

    switch (selectPageType(state)) {
      case PageType.Search: {
        const searchPersonalisationFeatureFlag =
          selectFeatureFlags(state)?.[SearchPersonalisation.NAME];
        const servingConfigMap = {
          [SearchPersonalisation.PERSONALISED]: 'default_search_personalised',
          [SearchPersonalisation.UNPERSONALISED]: 'default_search',
        };

        response = await getSearchListingsData({
          ...commonGetDataArgs,
          query,
          requestIsLoadTest,
          loggedInUserId: state.user.cognitoID || undefined,
          servingConfig:
            searchPersonalisationFeatureFlag && servingConfigMap[searchPersonalisationFeatureFlag],
          userId: state.userConfiguration.gaVisitorId,
        });
        break;
      }
      case PageType.Category: {
        const { previewing, ...restOfQuery } = query;
        const { gaVisitorId } = state.userConfiguration;
        const browseProviderFeatureFlag = selectFeatureFlags(state)?.[BrowseProvider.NAME];
        const browseProviderIsGoogle = browseProviderFeatureFlag === BrowseProvider.GOOGLE;
        const userId = browseProviderIsGoogle ? gaVisitorId : loop54UserId;

        response = await getCategoryListingsData({
          ...commonGetDataArgs,
          ...(previewing ? { previewing: true } : {}),
          query: restOfQuery,
          loggedInUserId: state.user.cognitoID || undefined,
          userId: userId,
          browseProvider: browseProviderIsGoogle ? 'google' : 'loop54',
        });
        if (response === null) {
          throw CATEGORY_NOT_FOUND_ERROR;
        }
        break;
      }
      case PageType.Partner: {
        const { shortcode, ...restOfQuery } = query;

        response = await getPartnerListingsData({
          ...commonGetDataArgs,
          query: restOfQuery,
          shortcode: shortcode ? String(shortcode) : selectPartnerShortcode(state),
          loggedInUserId: state.user.cognitoID || undefined,
          userId: loop54UserId,
        });
        if (response === null) {
          throw PARTNER_NOT_FOUND_ERROR;
        }
        break;
      }
      case PageType.AllProducts: {
        response = await getAllProductsListingsData({
          ...commonGetDataArgs,
          query,
          userId: loop54UserId,
        });
        break;
      }
      default:
        console.error('unknown page type');
    }

    if (response?.redirectUrl) {
      return { redirectUrl: response.redirectUrl };
    }

    const payload: RequestProductsActionPayload = {
      data: adaptBrowseDataAPIBodyToReduxActionPayload(response.data),
      triggerName,
    };

    return payload;
  },
);

export const requestProductsIsRejected = isRejected(requestProducts);
