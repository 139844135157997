import { getFeatureFlags } from '@noths/polaris-client-feature-flags';
import type { IncomingMessage } from 'http';

export const getFeatureFlagsWithMatchers = (req: IncomingMessage) => {
  return getFeatureFlags(req, {
    collapseFlagNames: [
      { newName: 'browse_provider', oldNameMatcher: /^browse_provider/m },
      { newName: 'search_personalisation', oldNameMatcher: /^search_personalisation/m },
    ],
  });
};
